import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { RktLinkModule, RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { environment } from 'environments/environment';

export interface TableData {
  category: string;
  detail: string;
}

const InfoCollectedTable: TableData[] = [
  {category: "Identifiers", detail: "Name, alias, postal address, online identifier (including IP address), email address, Social Security number, driver's license number, or other similar identifiers."},
  {category: "Client Records", detail: "Employer name, employment history, bank account number, credit card number, debit card number, and other financial information (including credit report, salary, and net worth)."},
  {category: "Protected Classification Characteristics Under State Or Federal Law", detail: "Age, gender, military status and marital status."},
  {category: "Commercial Information", detail: "Records of property, products or services purchased."},
  {category: "Internet Or Other Network Activity", detail: "Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement."},
  {category: "Geolocation Data", detail: "Physical location or movements."},
  {category: "Sensory Data", detail: "Audio (such as call recordings) or the contents of videos you choose to upload."},
  {category: "Professional Or Employment-Related Information", detail: "Current employer or employment status."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Preferences, characteristics and approximate location."},
  {category: "Sensitive Personal Information", detail:"Social Security Number, driver’s license, state identification card, or passport number; account log-In, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account; precise geolocation; racial or ethnic origin, religious or philosophical beliefs, or union membership; the contents of a consumer’s mail, email and text messages, unless the business is the intended recipient of the communication."}
];

const InfoSentTable: TableData[] = [
  {category: "Identifiers", detail: "Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Client Records", detail: "Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Protected Classification Characteristics", detail: "Service providers, Affiliates, mortgage partners, and partner companies."},
  {category: "Commercial Information", detail: "Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Internet Or Other Network Activity", detail: "Advertising networks and service providers (such as internet service providers, data analytics providers, data storage providers, operating systems and platforms)."},
  {category: "Geolocation Data", detail: "Partners and service providers."},
  {category: "Sensory Data", detail: "Partners."},
  {category: "Professional Or Employment-Related Information", detail: "Service providers and partners."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Partners, advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Sensitive Personal Information", detail:"Partners (such as mortgage partners or car dealerships, depending on the Services), advertising networks, service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms), affiliates, and partner companies."}
];

const SaleOfPersonalInfoTable: TableData[] = [
  {category: "Identifiers", detail: "Affiliates and non-affiliated third parties."},
  {category: "Categories Of Personal Information Described In Subdivision (e) Of Section 1798.80", detail: "Affiliates and non-affiliated third parties."},
  {category: "Commercial Information", detail: "Affiliates and non-affiliated third parties."},
  {category: "Internet Or Other Network Activity", detail: "Affiliates and non-affiliated third parties."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Affiliates and non-affiliated third parties."},
]

const UpdatedInfoCollectedTable: TableData[] = [
  {category: "Identifiers", detail: "Name, alias, postal address, online identifier (including IP address), email address, Social Security number, driver's license number, or other similar identifiers."},
  {category: "Client Records", detail: "Employer name, employment history, bank account number, credit card number, debit card number, and other financial information (including credit report, salary, and net worth)."},
  {category: "Protected Classification Characteristics Under State Or Federal Law", detail: "Age, race, ethnicity, gender, military status and marital status."},
  {category: "Commercial Information", detail: "Records of property, products or services purchased."},
  {category: "Internet Or Other Network Activity", detail: "Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement."},
  {category: "Geolocation Data", detail: "Physical location or movements."},
  {category: "Sensory Data", detail: "Audio (such as call recordings) or the contents of videos you choose to upload."},
  {category: "Professional Or Employment-Related Information", detail: "Current employer or employment status."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Preferences, characteristics and approximate location."},
  {category: "Sensitive Personal Information", detail:"Social Security Number, driver’s license, state identification card, or passport number; account log-In, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account; precise geolocation; racial or ethnic origin, religious or philosophical beliefs, or union membership; the contents of a consumer’s mail, email and text messages, unless the business is the intended recipient of the communication."}
];

const UpdatedInfoSentTable: TableData[] = [
  {category: "Identifiers", detail: "Partners (such as mortgage partners), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Client Records", detail: "Partners (such as mortgage partners ), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Protected Classification Characteristics", detail: "Service providers, Affiliates, mortgage partners, and partner companies."},
  {category: "Commercial Information", detail: "Partners (such as mortgage partners), advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Internet Or Other Network Activity", detail: "Advertising networks and service providers (such as internet service providers, data analytics providers, data storage providers, operating systems and platforms)."},
  {category: "Geolocation Data", detail: "Partners and service providers."},
  {category: "Sensory Data", detail: "Partners and service providers."},
  {category: "Professional Or Employment-Related Information", detail: "Service providers and partners."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Partners, advertising networks, and service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms)."},
  {category: "Sensitive Personal Information", detail:"Partners (such as mortgage partners), advertising networks, service providers (such as internet service providers, data analytics providers, email service providers, data storage providers, customer support providers, account management providers, operating systems and platforms), affiliates, and partner companies."}
];

const UpdatedSaleOfPersonalInfoTable: TableData[] = [
  {category: "Identifiers", detail: "Affiliate and non-affiliated third parties."},
  {category: "Categories of Personal Information Described in Subdivision (e) Of Section 1798.80", detail: "Affiliate and non-affiliated third parties."},
  {category: "Commercial Information", detail: "Affiliate and non-affiliated third parties."},
  {category: "Internet Or Other Network Activity", detail: "Affiliate and non-affiliated third parties."},
  {category: "Inferences Drawn From Other Personal Information", detail: "Affiliate and non-affiliated third parties."},
]

@Component({
  standalone: true,
  imports: [
    MatCardModule, 
    MatButtonModule, 
    RktLinkModule, 
    RktStackModule,
    MatDivider,
    MatExpansionModule,
    MatTableModule, 
    MatIconModule, 
    CommonModule],
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  providers: [AnalyticsService, MatAccordion]
})
export class PrivacyPolicyComponent implements OnInit {

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  public logoUrl?: string;
  tableColumns: string[] = ['category', 'detail'];
  InfoCollectedTable = InfoCollectedTable;
  InfoSentTable = InfoSentTable;
  SaleOfPersonalInfoTable = SaleOfPersonalInfoTable;
  UpdatedInfoCollectedTable = UpdatedInfoCollectedTable;
  UpdatedInfoSentTable = UpdatedInfoSentTable;
  UpdatedSaleOfPersonalInfoTable = UpdatedSaleOfPersonalInfoTable;
  showTableOfContents = true;

  constructor(
    private _analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this._analytics.pageViewEvent("Privacy Policy");
    this.logoUrl = environment.logoUrls.rocket;
  }

  ngAfterViewInit() {
    
  }

  printPrivacyPolicy() {
    this.showTableOfContents = false;
    setTimeout(() => {
      this.print();
      this.showTableOfContents = true;
    }, 200);
  }

  print() {
    window.print();
  }

  scrollToTop(event: any) {
    event.preventDefault();
    scrollTo(0,0);
  }
}
