import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class LoggerService {
  private url = `${environment.apiConsumerBaseV2}/log/request`;

  private headers = new HttpHeaders({
    "Content-Type": environment.headers.standard,
    "Accept": environment.headers.standard
  });

  constructor(private http: HttpClient) {}

  log(error: string, description: string) {
    const currentUTCTime = new Date().toISOString();
    const data = JSON.stringify({
        "logLevel": "Error",
        "logMessage": description,
        "logFrom": `[Source: RocketAccountWeb]:: Error ${error} OOPS page at:: ${currentUTCTime} and`
      });
    return this.http.post(this.url, data, {
      headers: this.headers
      }).subscribe();
  }
}