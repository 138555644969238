import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    standalone: true,
    selector: 'app-login',
    template: '',
    styles: '',
    providers: [AuthService],
})
export class LoginComponent implements OnInit {

    constructor(
        public auth: AuthService
    ) {}
    
    async ngOnInit() {
        this.auth.loginWithRedirect({
            authorizationParams: {
                "prompt": "login"
            }
        });
    }
}
