import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DoNotSellService } from 'app/services/do-not-sell.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import { oneName } from 'app/util/validators';
import { regex } from 'app/util/regex';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/services/generic/analytics.service';
import { RktStackModule } from '@rocketcentral/rocket-design-system-angular';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { UtilModule } from 'app/util/util.module';
import { RktLinkModule } from '@rocketcentral/rocket-design-system-angular';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { RktIconModule } from '@rocketcentral/rocket-design-system-angular';
import { RktAlertModule } from '@rocketcentral/rocket-design-system-angular';

@Component({
    standalone: true,
    imports: [
        MatButtonModule,
        RktStackModule,
        CommonModule, 
        UtilModule,
        FormsModule, 
        ReactiveFormsModule,
        MatInputModule,
        RktLinkModule,
        MatCheckboxModule,
        RktIconModule,
        RktAlertModule,
    ],
    selector: 'app-create-account-form',
    templateUrl: './do-not-sell.component.html',
    styleUrls: ['./do-not-sell.component.scss'],
    providers: [DoNotSellService, AnalyticsService, FormBuilder]
})

export class DoNotSellComponent implements OnInit {
    submitStatus: boolean = false;
    fieldsStatus: boolean = false;
    caliChecked: boolean = false;
    alertType?: string;
    siteKey: string = environment.recaptcha.siteKey;
    captchaToken: string = '';
    @ViewChild('recaptcha') recaptchaElement?: ElementRef;
    mailAddressForm: FormGroup;
    mainForm: FormGroup;
    errorCode?: number;
    public logoUrl?: string;

    constructor(
        private _doNotSellService: DoNotSellService,
        private fb: FormBuilder,
        private _analytics: AnalyticsService,
    ) {
        this.mailAddressForm = this.fb.group({
            addressLine1: ['', [Validators.minLength(2), Validators.required, Validators.pattern(regex.alphanumeric), Validators.maxLength(255)]],
            addressLine2: ['', [Validators.minLength(2), Validators.pattern(regex.alphanumeric), Validators.maxLength(255)]],
            city: ['', [Validators.minLength(2), Validators.required, Validators.pattern(/^[a-zA-Z`'\-. ]*$/), Validators.maxLength(50)]],
            state: ['', [Validators.minLength(2), Validators.required, Validators.pattern(regex.state), Validators.maxLength(2)]],
            zipCode: ['', [Validators.minLength(5), Validators.required, Validators.pattern(regex.numeric),  Validators.maxLength(5)]]
            });
        this.mainForm = this.fb.group({
            firstName: ['', [Validators.minLength(1), Validators.required, oneName(), Validators.pattern(/^[a-zA-ZÀ-ÿ`'\-. ]*$/), Validators.maxLength(50)]],
            lastName: ['', [Validators.minLength(1), Validators.required, oneName(), Validators.pattern(/^[a-zA-ZÀ-ÿ`'\-. ]*$/), Validators.maxLength(50)]],
            phoneNumber: ['', [Validators.required, Validators.pattern(regex.phone)]],
            emailAddress: ['', [Validators.required, Validators.pattern(regex.email), Validators.maxLength(128)]],
            mailingAddress: this.mailAddressForm
        });
    }

    async ngOnInit() {
        this._analytics.pageViewEvent("Do Not Sell");
        this.logoUrl = environment.logoUrls.rocket;
        this.initializeForms();
    }

    initializeForms()
    {        
        this.mainForm.disable();
        this.addRecaptchaScript();
    }

    onSubmit() {
        this.dismissAlert();
        this.submitDoNotSell(); 
    }

    submitDoNotSell() {        
        if (!this.captchaToken) {
            Object.keys(this.mainForm.controls).forEach( control => {
                this.mainForm.get(control)!.markAsTouched();
            });

            Object.keys(this.mailAddressForm.controls).forEach( control => {
                this.mailAddressForm.get(control)!.markAsTouched();
            });
        }
        else 
        {
            this._doNotSellService.postDoNotSell(this.mainForm.value, this.captchaToken).subscribe({
                next: data => {                            
                    this.mainForm.reset();
                    this.caliChecked = false;
                    this.alertType = 'success';
                },
                error: error => {
                    this.submitStatus = true;
                    this.alertType = 'error';
                    setTimeout(() => this.dismissAlert(), 10000);
                }});

            this.initializeForms();
        }
    }

    isCaliChanged(isCali: MatCheckboxChange) {
        const ischecked = isCali.checked;
        this.fieldsStatus = ischecked;

      if(ischecked){          
        this.mainForm.enable();
      } else {
        this.mainForm.disable();
      }
    }
   
    dismissAlert() {
        this.alertType = '';
    }

    addRecaptchaScript() {
        (window as any)['grecaptchaCallback'] = () => {
            this.renderReCaptcha();
        }
       
        (function(d, s, id, obj){
          let js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) 
          {
            return;
          }
          js = d.createElement(s); 
          js.id = id;
          (js as HTMLImageElement).src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
          fjs?.parentNode?.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));       
      }

      renderReCaptcha() {
        (window as any)['grecaptcha'].render(this.recaptchaElement?.nativeElement, {
          'sitekey' : this.siteKey,
          'callback': (response: string) => {
              this.captchaToken = response;
          }
        });
      }

    get firstName() {
        return this.mainForm.get('firstName');
    }

    get lastName() {
        return this.mainForm.get('lastName');
    }

    get emailAddress() {
        return this.mainForm.get('emailAddress');
    }

    get phoneNumber() {
        return this.mainForm.get('phoneNumber');
    }
   
    get addressLine1() {
        return this.mainForm.get('mailingAddress.addressLine1');
    }
    
    get addressLine2() {
        return this.mainForm.get('mailingAddress.addressLine2');
    }

    get city() {
        return this.mainForm.get('mailingAddress.city');
    }

    get state() {
        return this.mainForm.get('mailingAddress.state');
    }

    get zipCode() {
        return this.mainForm.get('mailingAddress.zipCode');
    }
}